module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/owenbean/ems/ems_web/src/components/DocumentationLayout.js","api_documentation":"/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js"},"gatsbyRemarkPlugins":[{"resolve":"/Users/owenbean/ems/ems_web/node_modules/gatsby-remark-images","id":"0e8ebe92-5306-517e-8c3b-d2bd38fba2db","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/Users/owenbean/ems/ems_web/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":240},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/owenbean/ems/ems_web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":240},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Response Time Track","short_name":"Repsonse Time Track","start_url":"/","background_color":"#eeeeee","theme_color":"#0099FF","display":"standalone","icon":"src/images/ems_tracker_logo_web.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4071858c8505d1e8f453b49380a9d7f"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
